<template>
  <div class="FontSettingsMain">
    <div>
      <p class="pageSettingsTitle">Font</p>
      <p class="pageSettingHeader">
        * Selected font will be used for every quiz page
      </p>
    </div>

    <div class="mt-1 d-flex align-items-center">
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_67_798)">
            <path
              d="M2.0835 3.33331V5.83331H6.25016V15.8333H8.75016V5.83331H12.9168V3.33331H2.0835ZM17.9168 7.49998H10.4168V9.99998H12.9168V15.8333H15.4168V9.99998H17.9168V7.49998Z"
              fill="#B3AFB6"
            />
          </g>
          <defs>
            <clipPath id="clip0_67_798">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="flex-grow-1">
        <select
          class="form-select w-100 form-control-sm pl-2 ml-2 input-border-style"
          aria-label="Default select example"
          @input="handleFontChange"
        >
          <option
            v-for="font in getFontFamilyList"
            :key="font.value"
            :value="font.value"
          >
            {{ font.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isFontUpdatedLoading: false,
    };
  },
  methods: {
    async handleFontChange(e) {
      let selectedFont = e.target.value;

      this.$store.dispatch('updateFontFamily',{oldFontFamily:null,newFontFamily:selectedFont})

      
      const allPages = [...this.getAllPages];

      try {
        this.isFontUpdatedLoading = true;

        allPages.forEach((item) => {
          if (item.value == "resultPage") {
            item.pageDesign.NoResultBlocksArray.forEach((element) => {
              switch (element.type) {
                case "text":
                  this.$set(element.style, "fontFamily", selectedFont);
                  break;
                case "button":
                  if (typeof element.content != "object") {
                    this.$set(element.style, "fontFamily", selectedFont);
                  } else {
                    this.$set(
                      element.content.backButton.style,
                      "fontFamily",
                      selectedFont
                    );
                    this.$set(
                      element.content.nextButton.style,
                      "fontFamily",
                      selectedFont
                    );
                  }
                  break;
              }
            });
          }
          item.pageDesign.blocksArray.forEach((element) => {
            switch (element.type) {
              case "text":
                this.$set(element.style, "fontFamily", selectedFont);
                break;
              case "button":
                if (typeof element.content != "object") {
                  this.$set(element.style, "fontFamily", selectedFont);
                } else {
                  this.$set(
                    element.content.backButton.style,
                    "fontFamily",
                    selectedFont
                  );
                  this.$set(
                    element.content.nextButton.style,
                    "fontFamily",
                    selectedFont
                  );
                }
                break;
              case "option":
                this.$set(element.style, "fontFamily", selectedFont);
                break;
              case "freeText":
                this.$set(element.style, "fontFamily", selectedFont);
                this.$set(element.style, "DateFontFamily", selectedFont);
                break;
              case "checkbox":
                this.$set(element.style, "fontFamily", selectedFont);
                break;
              case "product":
                this.$set(
                  element.style,
                  "bulletDescriptionfontFamily",
                  selectedFont
                );
                this.$set(element.style, "detailListFontFamily", selectedFont);
                this.$set(element.style, "fontFamily", selectedFont);
                this.$set(
                  element.style.productVariantStyles,
                  "fontFamily",
                  selectedFont
                );
                break;
            }
          });
        });

        this.$set(
          this.getProgressBar.progressStyles,
          "fontFamily",
          selectedFont
        );

        this.$nextTick(() => {
          this.$toasted.show("Font successfully applied", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });
      } catch (error) {
        console.log("Error Occurred", error);
        this.$toasted.show("Error Occurred while using Scheme", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isFontUpdatedLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getTotalPages", "getProgressBar"]),
    getFontFamilyList() {
      return fontFamilyList;
    },
    getAllPages() {
      return this.getTotalPages;
    },
  },
};
</script>
